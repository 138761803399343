import React from 'react';


export default (props) => (
  <div className="our-products_description--btn-more_container" {...props}>
    {
      Array(3).fill(null).map((item, index) => (
        <span
          key={String(index)}
          className="our-products_description--btn-more"
        />
      ))
    }
  </div>
);
