import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import Menu from './Menu';
import OurProducts from './OurProducts';
import AboutUs from './AboutUs';
import Cooperation from './Cooperation';
import Team from './Team';
import Footer from './Footer';
import ScrollButton from './ScrollToTopButton';


export default () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Helmet
        title="Center Cracow"
        meta={[
          { name: 'description', content: formatMessage({id: "meta.description"}) },
          { name: 'keywords', content: formatMessage({id: "meta.keywords"}) },
        ]}
      />

      <Menu />
      
      <main>
        <OurProducts />
        
        <AboutUs />
        
        <Cooperation />
        
        <Team />
      </main>
      
      <ScrollButton />
      
      <Footer />
    </>
  );
}
