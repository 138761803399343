import React from 'react';
import { FormattedMessage } from 'react-intl';


export default ({ name, position, small, image }) => (
  <div className={`section_team-team_member${small ? '--small' : ''}`}>
    <div className="section_team-team_member--image">
      {image}
    </div>
    <p className="section_team-team_member--position">
      <FormattedMessage id={position} />
    </p>
    <p className="section_team-team_member--name">{name}</p>
  </div>
);
