import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import AboutArticle from './AboutArticle';


const imageStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
};

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageHistory: imageSharp(fluid: {originalName: {eq: "background_history.png"}}) {
        fluid(pngQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      imageCurrent: imageSharp(fluid: {originalName: {eq: "background_current.jpg"}}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <div className="container">
      <section className="section_about-us" id="section-about-us">
        <h1><FormattedMessage id="aboutUsSection.heading" /></h1>

        <AboutArticle
          about={{
            headingId: 'aboutUsSection.headingHistory',
            messageId: 'aboutUsSection.textHistory',
            epoch: 'history',
          }}
          image={{
            position: 'front',
            className: 'about-us-history_image',
            node: (
              <Image
                fluid={data.imageHistory.fluid}
                style={imageStyle}
              />
            ),
          }}
        />

        <AboutArticle
          about={{
            headingId: 'aboutUsSection.headingCurrent',
            messageId: 'aboutUsSection.textCurrent',
            epoch: 'current',
          }}
          image={{
            position: 'end',
            className: 'about-us-current_image',
            node: (
              <Image
                fluid={data.imageCurrent.fluid}
                style={imageStyle}
              />
            ),
          }}
        />
      </section>
    </div>
  )
};
