import React from 'react';
import Image from 'gatsby-image';

import TeamMember from './TeamMember';


export default ({ team, data }) => (
  <>
    {team.map(({ name, position, photo }, index) => {
      const imgData = data.nodes.find(node => node.fixed.originalName === photo)
      const defaultImgData = data.nodes.find(node => node.fixed.originalName === 'team_management_placeholder.png')

      return (
        <TeamMember
          key={`${String(index)}_${name}_${position}`}
          name={name}
          position={position}
          image={<Image fixed={imgData ? imgData.fixed : defaultImgData.fixed} />}
        />
      )
    })}
  </>
);
