export default ({
  management: [
    {
       name: 'Leszek Koszałka',
       position: 'teamSection.partner',
       photo: 'team_management_01.png',
    },
    {
       name: 'Bogdan Koszałka',
       position: 'teamSection.managingDirector',
       photo: 'team_management_02.png',
    },
  ],
  staff_group_one: [
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_01.png',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_02.png',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: '',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_01.png',
    },
  ],
  staff_group_two: [
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_02.png',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_01.png',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_01.png',
    },
    {
      name: 'Name Surname',
      position: 'teamSection.position',
      photo: 'team_staff_02.png',
    },
  ]
});
