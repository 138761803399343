import React from 'react';
import { FormattedMessage } from 'react-intl';


export default ({ number, messageId }) => (
  <div className="cooperation_details">
    <p className="cooperation_details--number">{number}</p>
    <p className="cooperation_details--content">
      <FormattedMessage id={messageId} />
    </p>
  </div>
);
