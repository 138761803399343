export default ([
  {
    targetId: 'section-our-products',
    messageId: 'menuSection.products',
  },
  {
    targetId: 'section-about-us',
    messageId: 'menuSection.history',
  },
  {
    targetId: 'section-cooperation',
    messageId: 'menuSection.cooperation',
  },
  {
    targetId: 'section-team',
    messageId: 'menuSection.team',
  },
  {
    targetId: 'section-contact',
    messageId: 'menuSection.contact',
  },
]);
