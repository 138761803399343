import React from 'react';
import { FormattedMessage } from 'react-intl';


export default (
  {
    about: {
      headingId,
      messageId,
      epoch
    },
    image
  }
) => (
  <article className="section_about-us--article-wrapper">
    {image.position === 'front' && (
      <div className={image.className}>
        {image.node}
      </div>
    )}
    
    <div className={`about-us-${epoch}_description`}>
      <p className="about-us-history_heading">
        <FormattedMessage id={headingId} />
      </p>
      <p className="about-us-history_content">
        <FormattedMessage id={messageId} />
      </p>
    </div>
    
    {image.position === 'end' && (
      <div className={image.className}>
        {image.node}
      </div>
    )}
  </article>
);
