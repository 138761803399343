import React from 'react';

import logImg from '../../assets/img/center-cracow-logo.svg';

import DesktopMenu from './Desktop';
import MobileMenu, { SandwichButton } from './Mobile';
import menuData from './menuData';


export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuVisible: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState(({ isMobileMenuVisible }) => ({
      isMobileMenuVisible: !isMobileMenuVisible,
    }));
  };

  closeMobileMenu = () => {
    this.setState({
      isMobileMenuVisible: false,
    });
  };

  render() {
    const { isMobileMenuVisible } = this.state;

    return (
      <div className="navigation-wrapper">
        <nav className="navigation container">
          <div className="navigation-logo_container">
            <img src={logImg} alt="center-cracow-logo" className="navigation-logo" />
          </div>

          <DesktopMenu
            menuData={menuData}
          />

          <SandwichButton
            dynamicClassName={isMobileMenuVisible ? 'active' : ''}
            position={isMobileMenuVisible ? 'fixed' : 'absolute'}
            handleClick={this.toggleMobileMenu}
          />

          <MobileMenu
            menuData={menuData}
            menuCloser={this.closeMobileMenu}
            right={isMobileMenuVisible ? '0' : '-100%'}
          />
        </nav>
      </div>
    );
  }
};
