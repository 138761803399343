import React from 'react';
import { Link } from 'gatsby';


export default ({ mobile }) => (
  <div className={`navigation-language_change${ mobile ? '--mobile' : ''}`}>
    <Link to="/en/">en</Link>
    <Link to="/">pl</Link>
    <Link to="/hu/">hu</Link>
  </div>
);
