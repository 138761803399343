import React, { useEffect, useState, useCallback } from 'react';
import { animateScroll } from 'react-scroll';
import debounce from 'debounce';


let vh = 520;

export default () => {
  const [ className, setClassName ] = useState('scroll-btn');
  const { scrollToTop } = animateScroll;
  
  const scrollListener = useCallback(debounce((e) => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > (vh / 2)) {
        setClassName('scroll-btn visible')
      } else {
        setClassName('scroll-btn')
      }
    }
  },
  200), [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      window.addEventListener('scroll', scrollListener, { passive: true })
    }
    
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollListener)
      }
    };
  }, [scrollListener])

  return (
    <button
      className={className}
      onClick={scrollToTop}
    >
      <span className="scroll-btn_icon">↑</span>
    </button>
  );
};
