import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Management from './Management';
import Staff from './Staff';
import teamData from './teamData';


export default () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: {eq: "section_team_background.jpg"}) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      management: allImageSharp(filter: {fixed: {originalName: {regex: "/team_management/"}}}) {
        nodes {
          fixed(quality: 90, width: 320, height: 320) {
            originalName
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      staff: allImageSharp(filter: {fixed: {originalName: {regex: "/team_staff/"}}}) {
        nodes {
          fixed(quality: 90, width: 160, height: 160) {
            originalName
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section className="section_team" id="section-team">
      <div className="section_team-banner">
        <Image
          fluid={data.bgImage.childImageSharp.fluid}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        />
        <p style={{ position: 'relative' }}>Team</p>
      </div>
      <div className="section_team-wrapper">
        {data.management.nodes.length > 0 &&
          <Management team={teamData.management} data={data.management} />
        }
        
        {data.staff.nodes.length > 0 &&
          <Staff team={teamData.staff_group_one} data={data.staff} />
        }
        
        {data.staff.nodes.length > 0 &&
          <Staff team={teamData.staff_group_two} data={data.staff} />
        }
      </div>
    </section>
  )
};
