import React from 'react';

import MenuItem from '../MenuItem';
import LangSwitch from '../LangSwitch';


export default ({ menuData, right, menuCloser }) => (
  <div className="navigation-main_menu--mobile_wrapper" style={{ right }}>
    <ul className="navigation-main_menu--mobile">
      {
        menuData.map(menuItem => (
          <MenuItem
            key={menuItem.messageId}
            targetId={menuItem.targetId}
            messageId={menuItem.messageId}
            menuCloser={menuCloser}
          />
        ))
      }
    </ul>

    <LangSwitch mobile />
  </div>
);
