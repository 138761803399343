import React from 'react';


export default ({ uri, image }) => (
  <a
    href={uri}
    target="_blank"
    rel="noreferrer"
    className="styled-footer-recognitions_images"
  >
    {image}
  </a>
);
