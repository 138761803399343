export default ([
  {
    title: 'ourProductsSection.productNameFirst',
    messageId: 'ourProductsSection.productDescriptionFirst',
    picture: 'background_fields01.png',
  },
  {
    title: 'ourProductsSection.productNameSecond',
    messageId: 'ourProductsSection.productDescriptionSecond',
    picture: 'background_fields02.png',
  },
  {  
    title: 'ourProductsSection.productNameThird',
    messageId: 'ourProductsSection.productDescriptionThird',
    picture: 'background_fields03.png',
  }
]);
