export default ([
  {
    number: 1,
    messageId: 'cooperationSection.quality',
  },
  {
    number: 2,
    messageId: 'cooperationSection.approach',
  },
  {
    number: 3,
    messageId: 'cooperationSection.discounts',
  },
  {
    number: '...',
    messageId: 'cooperationSection.contactUs',
  },
]);
