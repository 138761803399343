import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll'


export default ({ targetId, messageId, menuCloser }) => (
  <li>
    <Link to={targetId} smooth={true} onClick={menuCloser}>
      <FormattedMessage id={messageId} />
    </Link>
  </li>
);
