import React from 'react';
import { FormattedMessage } from 'react-intl';
import debounce from 'debounce';

import MoreButton from './MoreButton';


class ProductDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductsDescription: false,
    };
  }
  
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resizeListener);
    }
  }
  
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resizeListener);
    }
  }
  
  resizeListener = debounce(() => {
    if (window.innerWidth > 1199 && this.state.showProductsDescription) {
      this.timeOutId = setTimeout(() => {
        this.setState({
          showProductsDescription: false,
        });
      }, 1000);
    }
  }, 200)
  
  handleOnClickShowDescription = () => {
    this.setState(({ showProductsDescription }) => ({
      showProductsDescription: !showProductsDescription,
    }));
  }

  render() {
    const { title, message, bgImage } = this.props;
    const { showProductsDescription } = this.state;
    
    return (
      <div className="our-products_banner">
        {bgImage}
        <div className={`our-products_banner--content ${showProductsDescription ? 'active-description' : '' }`}>
          <div className="our-products_banner-overlay"></div>
          <div className="our-products_banner-content_wrapper">
            <p className="our-products_banner-overlay_heading">
              <FormattedMessage id={title} />
            </p>
            <p className="our-products_description">
              <FormattedMessage id={message} />
            </p>
          </div>
        </div>

        <MoreButton onClick={this.handleOnClickShowDescription} />
      </div>
    );
  }
}

export default ProductDescription;
