import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import ProductDescription from './ProductDescription';
import productsData from './productsData';


export default () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp(
        filter: {
          fluid: {
            originalName: {
              regex: "/background_fields/"
            }
          }
        }
      ) {
        edges {
          node {
            id
            fluid(quality: 90, pngQuality: 90) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <div className="container">
      <section className="section_our-products" id="section-our-products">
        {
          productsData.map(({ title, messageId, picture: imageName }, index) => {
            const picture = data.allImageSharp.edges.find(
              edge => edge.node.fluid.originalName === imageName
            )
            
            return picture ? (
              <ProductDescription
                key={title}
                title={title}
                message={messageId}
                bgImage={(
                  <Image
                    fluid={picture.node.fluid}
                    style={{ position: 'absolute', width: '100%', height: '100%' }}
                  />
                )}
              />
            ) : null}
          )
        }
      </section>
    </div>
  )
};
