import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import cooperationData from './cooperationData';
import recognitionData from './recognitionData';
import CooperationDetail from './CooperationDetail';
import Recognition from './Recognition';


export default () => {
  const data = useStaticQuery(graphql`
    query {
      rzetelna: file(relativePath: {eq: "rzetelna_firma.png"}) {
        childImageSharp {
          fixed(width: 250, quality: 90, pngQuality: 90) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      gazele: file(relativePath: {eq: "certyfikaty_gazele.png"}) {
        childImageSharp {
          fixed(width: 250, quality: 90, pngQuality: 90) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      diamenty: file(relativePath: {eq: "diamenty_forbesa.jpg"}) {
        childImageSharp {
          fixed(width: 250, quality: 90, pngQuality: 90) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="container">
      <section className="section_cooperation" id="section-cooperation">
        <h2 className="cooperation_heading">
          <FormattedMessage id="cooperationSection.heading" />
        </h2>
        {cooperationData.map(detail => (
          <CooperationDetail
            key={String(detail.number)}
            number={detail.number}
            messageId={detail.messageId}
          />
        ))}
        <h2 className={'cooperation_details-recognitions_heading'}><FormattedMessage id={'cooperationSection.recognitions'}/></h2>
        <div className={'cooperation_details-recognitions container'}>
          {recognitionData.map(recognition => (
            <Recognition
              key={recognition.title}
              uri={recognition.uri}
              image={(
                <Image
                  alt={recognition.image.alt}
                  fixed={data[recognition.title].childImageSharp.fixed}
                />
              )}
            />
          ))}
        </div>
      </section>
    </div>
  )
};
