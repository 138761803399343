import React from 'react';


export default ({ dynamicClassName, position, handleClick }) => (
  <button
    className={`navigation-main_menu--mobile_icon-wrapper ${dynamicClassName}`}
    onClick={handleClick}
    style={{ position }}
  >
    {
      Array(4).fill(null).map(
        (item, index) => <span key={String(index)} className="main_menu-mobile-icon"></span>
      )
    }
  </button>
);
