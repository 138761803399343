export default ([
  {
    title: 'rzetelna',
    uri: 'https://wizytowka.rzetelnafirma.pl/3GCVKLJ2',
    image: {
      alt: 'logo-rzetelna-firma',
    }
  },
  {
    title: 'gazele',
    uri: 'https://www.pb.pl/gazele/gazela/5350',
    image: {
      alt: 'logo-gazele-biznesu',
    }
  },
  {
    title: 'diamenty',
    uri: 'https://www.forbes.pl/diamenty/2018/laureaci-diamentow-forbesa-woj-malopolskie/vrsrncx',
    image: {
      alt: 'logo-diamenty-forbes',
    }
  },
]);
